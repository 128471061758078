<script>
    let className = '';
    export { className as class };

    export let fluid = false;
    export let narrow = false;
</script>

<section
    class="{fluid
        ? 'w-full max-w-full'
        : narrow
        ? 'container md:max-w-screen-md'
        : 'container'} mx-auto px-4 md:px-8 {className}"
><slot/></section>
